import Vue from 'vue';
import Router from 'vue-router';
import Starter from './pages/StarterPage.vue';
import login from './pages/Login.vue';
import forgotpassword from './pages/components/ForgotPassword.vue';
import StarterNavbar from './layout/StarterNavbar.vue';
import StarterFooter from './layout/StarterFooter.vue';
import {i18n} from 'i18n';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Starter,
        header: StarterNavbar,
        footer: StarterFooter,
      },
      props: {
        header: { colorOnScroll: 400, i18n: i18n },
        footer: { backgroundColor: 'black' },
      }
    },
    {
      path: '/login',
      name: 'login',
      components: { default: login, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400, i18n:i18n },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      components: { default: forgotpassword, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400, i18n:i18n },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({ 
        top: document.querySelector(to.hash).offsetTop, 
        behavior: 'smooth' 
      })
    } else {
      return { x: 0, y: 0 }
    }
  }

});
