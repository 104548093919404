<template>
  <div>
    <div class="page-header clear-filter" filter-color= "blue">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header.jpg')">
        </parallax>      
      <div class="content-center">
        <div class="container">
          <img class="n-logo" src="img/large_logo.png" alt="" />
          <h1 class="title">Go far with data, analytics, and technology.</h1>
          <div class="text-center">
            <a v-for="item in social" :key="item.title" class="btn btn-primary btn-icon btn-round" :href="item.link">
            <i :class="item.class"></i></a>    
          </div>
        </div>
      </div>
    </div>
    <!-- ABOUT US -->
    <div class="section section-about-us" v-bind:id="$t('about_us.section')">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">{{ $t('about_us.title') }}</h2>
              <h5 v-for="item in $t('about_us.description')" :key="item.message" class="description">
                  {{item.message}}</h5>
              </div>
            </div>
          </div>
      </div>

    <div class="section section-about-us" v-bind:id="$t('our_method.section')" data-background-color="black">
      <div class="container">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="title">{{$t('our_method.title')}}</h2> 
          <p v-for="item in $t('our_method.description')" :key="item.message"> {{item.message}}  </p>
          </div>
      </div>
    </div>

      <div class="section-story-overview" data-background-color="black">                
        <div class="col-md-auto ml-auto mr-auto text-center align-self-center" >      
          <div class="row" v-for="row in $t('our_method.devops')" :key="row.col">           
            <div class="col-md-4 mr mr-auto align-self-center" v-for="item in row.content" :key="item.id">
            <template v-if="item.title !=='False'">
              <h3 class="title">{{item.title}}</h3>
              <p>{{item.message}}</p>
            </template>
              <template v-if="item.col === 5">
                <img src="img/devops.svg">
              </template>
            </div>
          </div>
        </div>
      </div>


<!-- OUR SOLUTIONS -->
<div class="section section-our-solutions" v-bind:id="$t('solutions.section')">
  <div class="container">
    <div class="col-md-8 ml-auto mr-auto text-center">
      <h2 class="title">{{$t('solutions.title')}}</h2> 
    </div>
    <div class="section-story-overview">
      <div class = "row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h5 v-for="item in $t('solutions.description')" :key="item.message" class="description">{{item.message}} </h5>
          </div>
        </div>
      <div class="row">
        <div class="col-md-4" v-for="areas in $t('solutions.areas')" :key="areas.name">
          <h3 class="title" align = "center"> {{areas.name}} </h3>
          <el-popover v-for="item in areas.content" 
            :key="item.ref"
            :ref="item.ref"
            popper-class="popover"
            placement="right"
            width="300"
            trigger="hover">
            <div class="popover-body">
            <div><img :src="item.src"><br></div>
            <div class="text-center" v-for="text in item.desc" :key="text.id">
              {{text.p}}<br>
            </div>
            </div>
          </el-popover>
          <router-link v-for="item in areas.content" :key="item.product" class="navbar-brand" to="/">{{item.product}}  </router-link>
        </div>
      </div>
    </div> 
  </div>
  </div>
<!-- OUR TOOLS -->
<div class="section section-our-solutions" v-bind:id="$t('tools.section')" data-background-color="black">
  
  <div class="container">
    <div class="col-md-8 ml-auto mr-auto text-center">
      <h2 class="title">{{$t('tools.title')}}</h2> 
    
    <div class="section-story-overview">
      <div class = "row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h5 v-for="item in $t('tools.description')" :key="item.message" class="description">{{item.message}} </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="section-story-overview" data-background-color="black"> 
    <carousel-section> </carousel-section>
  </div>               
</div>
</div>
<!-- OUR TEAM -->
<div class="section section-team text-center" :id="$t('team.section')" >
      <div class="container">
      <h2 class="title">{{$t('team.title')}}</h2> 
      
      <div class="row" v-for="rows in $t('team.members')" :key="rows.row">
        <template v-if="rows.nrow == 2">
        <div class="col-md-6" v-for="player in rows.content" :key="player.id">
          <div class="team-player">
            <div class='container-team'>
            <img :src="player.img" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised"/>
            </div> 
            <h4 class="title">{{player.name}}</h4>
            <p class="category text-primary">{{player.job}}</p>
            <p class="description" v-for="text in player.description" :key="text.p">{{text.p}} </p>
            <a v-for="item in player.social" :key="item.title" class="btn btn-primary btn-icon btn-round" :href="item.link">
            <i :class="item.icon"></i></a>
          </div>
        </div>
        </template>
        <template v-else-if="rows.nrow == 1">
        <div class="col-md-12" v-for="player in rows.content" :key="player.id">
          <div class="team-player">
            <div class='container-team'>
            <img :src="player.img" alt="Thumbnail Image" class="rounded-circle img-fluid img-raised"/>
            </div> 
            <h4 class="title">{{player.name}}</h4>
            <p class="category text-primary">{{player.job}}</p>
            <p class="description" v-for="text in player.description" :key="text.p">{{text.p}} </p>
            <a v-for="item in player.social" :key="item.title" class="btn btn-primary btn-icon btn-round" :href="item.link">
            <i :class="item.icon"></i></a>
          </div>
        </div>
        </template>
        <template v-else>
          <div class="col-md-4" v-for="player in rows.content" :key="player.id">
            <div class="team-player">
              <div class='container-team'>
              <img :src="player.img" alt="Thumbnail Image" class="team rounded-circle img-fluid img-raised" />
              </div>
              <h4 class="title">{{player.name}}</h4>
              <p class="category text-primary">{{player.job}}</p>
              <p class="description text-justify" v-for="text in player.description" :key="text.p">{{text.p}} </p>
              <a v-for="item in player.social" :key="item.title" class="btn btn-primary btn-icon btn-round" :href="item.link">
               <i :class="item.icon"></i></a>      
              

            </div>
          </div>
        </template>
      </div>
      </div>
  </div>
<!-- CONTACT US -->
<div class="section section-team text-center" :id="$t('contact.section')" >
  <div class="container">
    <h2 class="title">{{$t('contact.title')}}</h2>
    <h1 class="text-info"> <b>{{$t('contact.description')}} </b> </h1>
  </div>
</div>


</div>  
</template>

<script>

import { Button, FormGroupInput } from '@/components';
import { Popover, Carousel, CarouselItem  } from 'element-ui';
import CarouselSection from './components/CarouselSection';

export default {
  name: 'starter',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Popover.name]: Popover,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    CarouselSection
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      social:[  {title: 'Linkedin',link:'https://www.linkedin.com/company/deepmetrics',class: 'fab fa-linkedin'},
                {title: 'Github',link:'https://github.com/deepmetrics',class: 'fab fa-github'},
                {title: 'Facebook',link:'https://www.facebook.com/deepmetricsai',class: 'fab fa-facebook-square'},
                {title: 'Twitter',link:'https://twitter.com/metrics_deep',class: 'fab fa-twitter'}]
    };
  }
};
</script>
<style>
.starter-page {
  min-height: calc(100vh - 95px);
}

.container-team {
  position: relative;
}

.container-team img{
 filter: sepia(0.3) contrast(1) brightness(0.75) grayscale(1) ;  
}
.container-team img:hover{
 filter: none
}

.container-team .overlay a {
  position: relative;
  opacity: 0;
  text-align: center;
  color: white;
  border: solid 2px white;
  z-index: 1;
}

.container-team:hover .overlay .btn {
  opacity: 1;
}


</style>



