<template>
  <div class="section" id="carousel" data-background-color="black">
      <div class="row justify-content-center">
        <div class="col-8">
          <el-carousel height="540px">
            <el-carousel-item v-for="area in areas" :key="area.name">
              <img class="d-block" :src="area.img" alt="First slide" />
              <div class="carousel-caption d-none d-md-block" data-interval="10000">
                <h5>{{area.name}}</h5>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  data(){
    return {
      areas: [
        {name:'Technology Solutions', img:'img/tools/TS_tools.jpg'},
        {name:'Advanced Analytics', img:'img/tools/AA_tools.jpg'},
        {name:'Risk Analytics', img:'img/tools/RA_tools.jpg'}
      ]
    }
  }
};
</script>
<style></style>
