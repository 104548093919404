import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)

const messages = {
    en:{
        language: 'English',
        nav: [  {link: '#about_us', title: 'About Us',len: 'en' },
                {link: '#Our_method', title: 'Our method', len: 'en'},
                {link: '#solutions', title: 'Our Solutions', len: 'en'},
                {link: '#tools', title: 'Our Tools', len:'en'},
                {link: '#team', title:'Our team', len:'en'},
                {link: '#contact',title:'Contact', len:'en'} ],

        about_us:{  section: "about_us", 
                    title: "Who we are?", 
                    description: [  {id:1, message:"We are an advanced analytics firm that provides disruptive solutions through cutting-edge technologies to improve our clients."},
                                    {id:2, message:"DeepMetrics was born from a group of Data Scientists and Data Engineers who believe that data, analytics and technology are key to make a deep impact on the performance of any organization."}
                    ]
                },
        
        our_method:{    section: "Our_method",
                        title: "Our method",
                        description: [
                            { id:1, message:"We design and deploy solutions suited to our clients that come from a deep understanding of their problems, capacities and necessities. Our goal is they can be the best version of themselves."},
                            { id:2, message:"Our promise is to achieve a deep and meaningful impact on our clients performance with our data and technology driven solutions."}
                        ],
                        
                        devops: [
                            {id:1,content:[
                                {col:1, title:"False",message:"False"},
                                {col:2, title: 'Evaluation & Feedback', message: 'We measure the impact of our implemented solutions'},
                                {col:3, title:"False",message:"False"}
                            ]},
                            {id:2,content:[
                                {col:4, title: 'Deep understanding', message: 'We diagnose, quantify and prioritize improvement opportunities'},
                                {col:5, title: 'False', message:'False', img:'img/devops.svg'},
                                {col:6, title : 'Implementation & Deployment', message:'We provide productive implementations with impact, on your systems'}
                            ]},
                            {id:3,content:[
                                {col:7, title:"False",message:"False"},
                                {col:8, title: 'Design solutions', message: 'We define the best solution that fits your organization'},
                                {col:9, title:"False",message:"False"}
                            ]}
                        ]
                          
                    },
        solutions:{ section: 'solutions',
                    title: 'Our Areas & Solutions',
                    description: [{id: 1, message: "We adapt to your problems, however we have areas of expertise and solutions designed for you:"}],
                    areas: [
                        {name: 'Technology Solutions',
                        content:[
                            {product:'Visualization and monitoring', desc:[{p:'We provide tools for the analysis of your data.'},{p: 'We create automatic monitoring for your relevant systems and metrics.'}],ref:'TS1',src:'img/visualization.png',place:'right'},
                            {product:'Automatization', desc:[{p:'We automate recurring reports to speed up the flow of information.'},{p:'Automatic data processing and data source integration'}],ref:'TS2',src:'img/reporting.png',place:'right'},
                            {product:'RPA (Virtual assistants)', desc:[{p:'Develop bots to facilitate frequent questions or automated recurring massive tasks.'},{p:'Increase your ability to reach your customers in a massive and personalized way, with a differentiated value proposition.'}],ref:'TS3',src:'img/rpa.png',place:'right'}
                        ]},
                        {name: 'Advanced Analytics',
                        content:[
                            {product:'Recommendation systems', desc:[{p:'Systems for generating recommendations based on the behaviors observed in the data.'}],ref:'AA1',src:'img/recommend.png',place:'right'},
                            {product:'Predictive models', desc:[{p:'Systems that estimate the expected values ​​in the future for critical business variables (sales level, prices or anomaly detection)'}],ref:'AA2',src:'img/predictive.png',place:'right'},
                            {product:'Segmentation models', desc:[{p:'Systems that group observations (customers, transactions, etc.) to describe them, define a generic behavior or detect anomalous behaviors.'}],ref:'AA3',src:'img/segment.png',place:'right'}

                        ]},
                        {name: 'Risk Analytics',
                        content:[
                            {product:'Prescriptive analytics', desc:[{p:'Find the best course of action of a scenario with the available data, taking advantage of the predictions.'}],ref:'RA1',src:'img/prescriptive.png',place:'right'},
                            {product:'Optimization, simulation & prediction', desc:[{p:'Decision-making based on data, guided by your objective function, and with your restrictions, emulating potential scenarios.'}],ref:'RA2',src:'img/target.png',place:'right'},
                            {product:'Scoring models', desc:[{p:'Systematization in decision-making guided by observable variables, in terms of risk, customer prioritization, among others.'}],ref:'RA3',src:'img/traffic.png',place:'right'}
                        ]}
                    ]


        },
        tools:{
            section: 'tools',
            title: 'Our Tools',
            description: [{id:1, message:"We try to find the solution that best suits your needs, that's why we work with different tools"}
            ]
        },
        team:{
            section: 'team',
            title: 'Here is our team',
            description: [],
            members: [{
                row:1,
                nrow:2,
                content:[                
                    {id:1, 
                    img:'img/rweber.jpg',
                    name:'Richard Weber H.',
                    job:'Senior scientist & Ambassador', 
                    description:[{p:'PhD Operations Research, RWTH Aachen University'},{p:'MSc. Operations Research, RWTH Aachen University'},{p:'Diploma in Mathematics, RWTH Aachen University'}],
                    social:[{title:'Linkedin',link:"https://www.linkedin.com/in/richard-weber-243528/",icon:'fab fa-linkedin'},
                    {title:'Twitter',link:'https://twitter.com/Richard_Weber',icon:'fab fa-twitter'}
                    ]
                    },
                    {id:2, 
                    img:'img/giorgio.jpg',
                    name:'Giorgiogiulio Parra De Blasi',
                    job:'CEO', 
                    description:[{p:'MSc. in Operations Management, Universidad de Chile.'},{p:'Industrial Engineering, Universidad de Chile.'},{p:' Minor in Game Theory.'}],
                    social:[{title:'Linkedin',link:'https://www.linkedin.com/in/giorgiogiulio-parra-de-blasi-58a07228/',icon:'fab fa-linkedin'},
                            {title:'Twitter',link:'https://twitter.com/gpdchile',icon:'fab fa-twitter'}]
                }]
                },
                {row:2,
                nrow:3,
                content:[
                    {id:3, 
                    img:'img/agarrido.jpg',
                    name:'Andrés Garrido C.',
                    job:'Head of risk analytics', 
                    //description:[{p:'Desde su paso por la Universidad, Andrés ha sido una persona inquieta, participando y desarrollando diversos tipos de proyectos, apasionado por resolver problemas desafiantes que generan impacto, con experiencia Operaciones, modelos de riesgo y análisis de grandes volúmenes de información.'},
                    //             {p:' Sus hobbies son viajar, el cine, el café y la cerveza artesanal.'}], 
                    description:[{p:'MSc. in Operations Management, Universidad de Chile.'},{p:' Industrial Engineering, Universidad de Chile.'}],
                    social:[
                        {title:'Linkedin',link:'https://www.linkedin.com/in/andres-garrido-castro-20882b39/',icon:'fab fa-linkedin'},
                        {title:'Github',link:'https://github.com/angarr',icon:'fab fa-github'},
                        {title:'Twitter',link:'https://twitter.com/angarr14',icon:'fab fa-twitter'}]
                    },
                    {id:4, 
                    img:'img/ssantana.jpg',
                    name:'Sebastián Santana R.',
                    job:'Head of advanced analytics', 
                    //description:[{p:'Sebastián ha desarrollado una pasión por aprender las últimas tecnologías y el desarrollo de algoritmos de machine learning para generar predicción o recomendaciones y el análisis de textos de forma automática. Con aplicaciones en diversos rubros como Salud, Sector público, finanzas y retail.'},
                    //             {p:'Sus hobbies son cocinar, los motores, la tecnología y los vinos'}],
                    description:[{p:'Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                    social:[
                        {title:'Linkedin',link:"https://www.linkedin.com/in/ssantanar/",icon:'fab fa-linkedin'},
                        {title:'Twitter',link:'https://twitter.com/ssantanar',icon:'fab fa-twitter'}
                    ]
                    },
                    {id:5, 
                        img:'img/aperez.jpg',
                        name:'Alexis Pérez R.',
                        job:'Head of technology solutions', 
                        description:[{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                        social:[]
                        }]
                    },
                    {row:3,
                        nrow:2,
                        content:[
                            {id:6,
                                img:'img/pperez.jpg',
                                name:'Pablo Pérez M.',
                                job: 'Opportunity Manager',
                                description: [{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                                social:[{title:'Linkedin',link:'https://www.linkedin.com/in/pabloperezmontecinos/',icon:'fab fa-linkedin'},
                                         {title:'Web', link:'https://www.pabloperez.cl/', icon:'now-ui-icons business_globe'},
                                         {title:'Twitter',link:'https://twitter.com/pabloperez_',icon:'fab fa-twitter'}]
                            },
                            {id:7,
                                img:'img/fm.jpg',
                                name:'Fernando Marshall B.',
                                job: 'Data Scientist',
                                description: [{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                                social:[{title:'Linkedin',link:'https://www.linkedin.com/in/fernando-marshall-boehmwald-62a923163/',icon:'fab fa-linkedin'}]
                            }]
                            }
            ]
        },
        contact:
        {
            section:'contact',
            title:'Contact Us!',
            description:'hello@deepmetrics.ai'
        }
        ,
        login:{
            img: 'img/login.jpg',
            user: 'Username ...',
            pass: 'Password ...',
            go: 'Get Started!',
            create: 'Create Account',
            help: 'Forgot Password?',
            forgotpass: {title:'Forgot Password', user:'Username ...', go: 'Reset Password'},
            someBlank: 'You cannot leave your username or password blank, please check it',
            userPassFail: 'Your username or password is incorrect, please check.',
            apiFail: 'Detectamos un problema con el sistema, por favor envienos un ticket',
        }

        
    }, 

    es:{
        language: 'Español',
        nav:[
            {link: '#about_us', title: 'Nosotros',len: 'en' },
            {link: '#Our_method', title: 'Metodologia', len: 'en'},
            {link: '#solutions', title: 'Soluciones', len: 'en'},
            {link: '#tools', title: 'Herramientas', len:'en'},
            {link: '#team', title:'Equipo', len:'en'},
            {link: '#contact',title:'Contacto', len:'en'} ],
        
        about_us: {section: "about_us", 
                    title: "Quienes somos?", 
                    description: [
                                {id:1, message:"Somos una empresa de analítica avanzada que desarrolla soluciones disruptivas con tecnología de vanguardia para mejorar a nuestros clientes."},
                                {id:2, message:"DeepMetrics nace de un grupo de Data Scientists y Data Engineers que creen que los datos, la analítica y la tecnología son claves para crear un profundo impacto en el desarrollo de cualquier tipo de organización."}
                            ]    
                },
        our_method:{    section: "Our_method",
                        title: "Nuestra Metodología",
                        description:[{ id:1, message:"Diseñamos e implementamos soluciones que se adaptan a nuestros clientes, que provienen de un profundo entendimiento de sus problemas, capacidades, y necesidades. Nuestro objetivo es que puedan ser la mejor versión de si mismos."},
                                     { id:2, message:"Nuestra promesa es lograr un profundo y significativo impacto en el desempeño de nuestros clientes con nuestras soluciones basadas en datos y tecnología."}
                        ],
                        devops: [
                            {id:1,content:[
                                {col:1, title:"False",message:"False"},
                                {col:2, title: 'Evaluation & Feedback', message: 'Medimos el impacto de la soluciones implementadas'},
                                {col:3, title:"False",message:"False"}
                            ]},
                            {id:2,content:[
                                {col:4, title: 'Deep understanding', message: 'Diagnosticamos, cuantificamos y priorizamos oportunidades de mejora'},
                                {col:5, title: 'False', message:'False', img:'img/devops.svg'},
                                {col:6, title : 'Implementation & Deployment', message:'Generamos implementaciones productivas de impacto en tus sistemas'}
                            ]},
                            {id:3,content:[
                                {col:7, title:"False",message:"False"},
                                {col:8, title: 'Design solutions', message: 'Diseñamos soluciones a la medida de tu organización'},
                                {col:9, title:"False",message:"False"}
                            ]}
                        ]
                    },
            solutions:{ section: 'solutions',
                        title: 'Nuestras Áreas & Servicios',
                        description: [{id: 1, message: "Nos adaptamos a tus problemas, sin embargo tenemos áreas de expertiz y soluciones diseñadas para ti:"}],
                        areas: [
                            {name: 'Technology Solutions',
                            content:[
                                {product:'Visualización & monitoreo', desc:[{p:'We provide tools for the analysis of your data.'},{p: 'We create automatic monitoring for your relevant systems and metrics.'}],ref:'TS1',src:'img/visualization.png'},
                                {product:'Automatización', desc:[{p:'We automate recurring reports to speed up the flow of information.'},{p:'Automatic data processing and data source integration'}],ref:'TS2',src:'img/reporting.png'},
                                {product:'RPA (Asistentes virtuales)', desc:[{p:'Develop bots to facilitate frequent questions or automated recurring massive tasks.'},{p:'Increase your ability to reach your customers in a massive and personalized way, with a differentiated value proposition.'}],ref:'TS3',src:'img/rpa.png'}
                            ]},
                            {name: 'Advanced Analytics',
                            content:[
                                {product:'Sistemas de recomendación', desc:[{p:'Systems for generating recommendations based on the behaviors observed in the data.'}],ref:'AA1',src:'img/recommend.png'},
                                {product:'Modelos predictivos', desc:[{p:'Systems that estimate the expected values ​​in the future for critical business variables (sales level, prices or anomaly detection)'}],ref:'AA2',src:'img/predictive.png'},
                                {product:'Modelos de segmentación', desc:[{p:'Systems that group observations (customers, transactions, etc.) to describe them, define a generic behavior or detect anomalous behaviors.'}],ref:'AA3',src:'img/segment.png'}

                            ]},
                            {name: 'Risk Analytics',
                            content:[
                                {product:'Analítica prescriptiva', desc:[{p:'Find the best course of action of a scenario with the available data, taking advantage of the predictions.'}],ref:'RA1',src:'img/prescriptive.png'},
                                {product:'Optimización, simulación & predicción', desc:[{p:'Decision-making based on data, guided by your objective function, and with your restrictions, emulating potential scenarios.'}],ref:'RA2',src:'img/target.png'},
                                {product:'Modelos de scoring', desc:[{p:'Systematization in decision-making guided by observable variables, in terms of risk, customer prioritization, among others.'}],ref:'RA3',src:'img/traffic.png'}
                            ]}
                        ]


            },
        tools:{
                section: 'tools',
                title: 'Nuestras Herramientas',
                description: [{id:1, message:"Nos consideramos agnósticos tecnológicos y tratamos de buscar la mejor solución que se adapte a tus necesidades, es por eso que trabajamos con diversas herramientas:"}
                ]
            },
            team:{
                section: 'team',
                title: 'Este es Nuestro Equipo',
                description: [],
                members: [{
                    row:1,
                    nrow:2,
                    content:[                
                        {id:1, 
                        img:'img/rweber.jpg',
                        name:'Richard Weber H.',
                        job:'Senior scientist & Ambassador', 
                        description:[{p:'PhD Operations Research, RWTH Aachen University'},{p:'MSc. Operations Research, RWTH Aachen University'},{p:'Diploma in Mathematics, RWTH Aachen University'}],
                        social:[{title:'Linkedin',link:"https://www.linkedin.com/in/richard-weber-243528/",icon:'fab fa-linkedin'},
                                {title:'Twitter',link:'https://twitter.com/Richard_Weber',icon:'fab fa-twitter'}
                    ]
                        },
                        {id:2, 
                        img:'img/giorgio.jpg',
                        name:'Giorgiogiulio Parra De Blasi',
                        job:'CEO', 
                        description:[{p:'MSc. in Operations Management, Universidad de Chile.'},{p:' Industrial Engineering, Universidad de Chile.'},{p:' Minor in Game Theory.'}],
                        social:[{title:'Linkedin',link:'https://www.linkedin.com/in/giorgiogiulio-parra-de-blasi-58a07228/',icon:'fab fa-linkedin'},
                        {title:'Twitter',link:'https://twitter.com/gpdchile',icon:'fab fa-twitter'}]
                        }]
                    },
                    {row:2,
                    nrow:3,
                    content:[
                        {id:3, 
                        img:'img/agarrido.jpg',
                        name:'Andrés Garrido C.',
                        job:'Head of risk analytics', 
                        description:[{p:'MSc. in Operations Management, Universidad de Chile.'},{p:' Industrial Engineering, Universidad de Chile.'}],
                        social:[
                            {title:'Linkedin',link:'https://www.linkedin.com/in/andres-garrido-castro-20882b39/',icon:'fab fa-linkedin'},
                            {title:'Github',link:'https://github.com/angarr',icon:'fab fa-github'},
                            {title:'Twitter',link:'https://twitter.com/angarr14',icon:'fab fa-twitter'}]
                        },
                        {id:4, 
                        img:'img/ssantana.jpg',
                        name:'Sebastián Santana R.',
                        job:'Head of advanced analytics', 
                        description:[{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                        social:[
                            {title:'Linkedin',link:"https://www.linkedin.com/in/ssantanar/",icon:'fab fa-linkedin'},
                            {title:'Twitter',link:'https://twitter.com/ssantanar',icon:'fab fa-twitter'}
                        ]
                        },
                        {id:5, 
                            img:'img/aperez.jpg',
                            name:'Alexis Pérez R.',
                            job:'Head of technology solutions', 
                            description:[{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                            social:[]
                        }]
                    },
                    {row:3,
                    nrow:2,
                    content:[
                        {id:6,
                            img:'img/pperez.jpg',
                            name:'Pablo Pérez M.',
                            job: 'Opportunity Manager',
                            description: [{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                            social:[{title:'Linkedin',link:'https://www.linkedin.com/in/pabloperezmontecinos/',icon:'fab fa-linkedin'},
                                     {title:'Web', link:'https://www.pabloperez.cl/', icon:'now-ui-icons business_globe'},
                                     {title:'Twitter',link:'https://twitter.com/pabloperez_',icon:'fab fa-twitter'}]
                        },
                        {id:7,
                            img:'img/fm.jpg',
                            name:'Fernando Marshall B.',
                            job: 'Data Scientist',
                            description: [{p:' Industrial Engineering, Universidad de Chile.'},{p:'BSc. in Industrial Engineering, Universidad de Chile'}],
                            social:[{title:'Linkedin',link:'https://www.linkedin.com/in/fernando-marshall-boehmwald-62a923163/',icon:'fab fa-linkedin'}]
                        }]
                        }
                ]
            },
            contact:
            {
                section:'contact',
                title:'Contáctanos!',
                description: 'hello@deepmetrics.ai'
            },
            login:{
                img: 'img/login.jpg',
                user: 'Usuario ...',
                pass: 'Contraseña ...',
                go: 'Empecemos!',
                create: 'Crear Cuenta',
                help: 'Olvidaste Contraseña?',
                forgotpass: {title:'Contraseña Olvidada', user:'Usuario ...', go: 'Reiniciar contraseña'},
                someBlank: 'No puedes dejar tu usuario o contraseña en blanco, por favor verificar',
                userPassFail: 'Tu usuario o contraseña es incorrecto, por favor verificar',
                apiFail: 'Si hay algún problema con el sistema, por favor envíanos un ticket'
            }
    }
};

export default new VueI18n({ 
    locale: 'en',                                       // set locale    
    fallbackLocale: 'es',                               // set fallback locale    
    messages,                                           // set locale messages
});
