<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/login.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <img class="card-img-top" v-lazy="'img/large_logo.png'"/>
          <form id='login-form'>
          <card type="login" plain>
            
            <fg-input
              class="no-border input-lg"
              addon-left-icon="now-ui-icons users_circle-08"
              id="username"
              type="email"
              :placeholder="$t('login.user')"
              v-model="user"
            >
            </fg-input>

            <fg-input
              class="no-border input-lg"
              addon-left-icon="now-ui-icons text_caps-small"
              id="password"
              type="password"              
              :placeholder="$t('login.pass')"
              v-model="pass"
            >
            </fg-input>
            
            <alert v-if="notification" type="warning" show variant="warning">{{notificationMessage}}</alert>

            <div class="card-footer text-center">
              <button
                type="button"
                class="btn btn-primary btn-round btn-lg btn-block"
                v-on:click="login"
              >
                {{$t('login.go')}}
              </button>
            </div>

<!--              <div class="pull-left">
                <h6>
                  <router-link tag="a" to="/login" class="link footer-link">{{$t('login.create')}}</router-link>
                </h6>
              </div>
              <div class="pull-right">
                <h6>
                  <router-link class="link footer-link" to ="/forgot-password"  > 
                  <a>{{$t('login.help')}}</a>
                  </router-link>
                </h6>
              </div>
-->
          </card>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Card, Button, FormGroupInput, Alert } from '@/components';
import MainFooter from '@/layout/MainFooter';

export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    Alert,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      pass: '',
      user: '',
      notification: false,
      notificationMessage: '',
    }
  },
  methods: {
    login: async function() {
      if(this.user && this.pass){
        const axios = require('axios');
        let resp;
        try {
          resp = await axios.post(`/login/${this.user}`, {
            user: this.user,
            pass: this.pass,
            service: 'login-webpage'
          });
        } catch (err) {
          this.notification = true;
          this.notificationMessage = this.$t('login.apiFail');
        }

        let check = false;
        if(!check){
          this.notification = true;
          this.notificationMessage = this.$t('login.userPassFail');
        } else {
          window.location.href = '/login';
        }
      } else {
        this.notification = true;
        this.notificationMessage = this.$t('login.someBlank');
      }
    },
  }

};
</script>
<style>
</style>
