<template>
  <navbar position="fixed" type="primary" menu-classes="ml-auto">
    <template slot-scope="{ toggle, isToggled }">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
      <img class="n-logo" src="img/large_logo.png" style="max-width:200px; margin-top: -7px;" />
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="300"
        trigger="hover"
      >
        <div class="popover-body">
          <div class="text-center">
          Go Far with data, analytics, and technology 
          </div>
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class = "nav-item" v-for="entry in $t('nav') " :key="entry.link">
       <router-link tag="a" class = "navbar-brand" :to="{ name: 'index', hash:entry.link }"> {{entry.title}} </router-link>
      </li>
      <li class="nav-item">
      <router-link to="/login">
      <a class="nav-link"> <p>Login</p> </a>
      </router-link>
      </li>
      <li class="nav-item" v-for="locale in languages" :key="locale.title">
        <a class = "nav-link" @click="changeLoc(locale.language)"> 
        <flag :iso="icons[locale.language]" v-bind:squared=false />{{locale.title}}
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover
  },
  data() { return {        
    languages: [ 
                    {flag: 'us', language: 'en', title: 'EN' },
                    {flag: 'es', language: 'es', title: 'ES' }
                    ],
    icons:{
      'en': 'us',
      'es': 'es'
    }
    }   
    },
  computed: {
        currentLocale() {
            return this.$i18n.locale
        }
        },
  methods: { 
    changeLoc(loc) {
       this.$i18n.locale = loc
    }
  }

};

</script>

<style scoped></style>
