<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('img/login.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">
          <img class="card-img-top" v-lazy="'img/large_logo.png'"/>
            <div class = 'title'>
                {{$t('login.forgotpass.title')}}
            </div>
          <form id='forget-pass'>
          <card type="login" plain>


            <fg-input
              class="no-border input-lg"
              addon-left-icon="now-ui-icons users_circle-08"
              id="username-forgot"
              type="email"
              :placeholder="$t('login.forgotpass.user')"
              v-model="UserValue"
            >
            </fg-input>
            
              <div class="card-footer text-center">
                <router-link tag="a" to="/login"
                  class="btn btn-primary btn-round btn-lg btn-block"  v-on:click="login()"
                  >{{$t('login.forgotpass.go')}} </router-link>
              </div>
            
              <div class="pull-left">
                <h6>
                  <router-link tag="a" to="/login"
                  class="link footer-link">{{$t('login.create')}}</router-link>
                </h6>
              </div>
          </card>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
export default {
  name: 'login-page',
  bodyClass: 'login-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
      data() {
      return {
        UserValue: '',
      }
    }


};
</script>
<style></style>
