import { Parallax, Button } from '../components';
/**
 * You can register global components here
 */

const globalComponents = {
  install(Vue) {
    Vue.component(Parallax.name, Parallax);
    Vue.component(Button.name, Button);

  }
};

export default globalComponents;
